import React from "react";
import about from "../../assets/about.jpeg";

const AboutUs = () => {
  const sectionStyle = {
    position: "relative",
    width: "100%",
    height: "auto",
  };

  const imgStyle = {
    height: "550px",
    width: "100%",
    objectFit: "cover",
  };

  const cardStyle = {
    position: "absolute",
    bottom: "-20px",
    right: "10%",
    backgroundColor: "white",
    padding: "20px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    textAlign: "center",
    width: "80%",
    maxWidth: "500px",
    height: "500px",
  };

  const headingStyle = {
    fontSize: "24px",
    color: "#333",
    justifyContent: "start",
  };

  const paragraphStyle = {
    margin: "10px 0 0",
    fontSize: "16px",
    color: "#666",
  };

  return (
    <section style={sectionStyle} id="AboutUs">
      <div>
        <img src={"https://t4.ftcdn.net/jpg/03/83/92/97/360_F_383929765_qog0qXY2atgSmm71khUWr7zF4fnnL6Q1.jpg"} alt="About Us" style={imgStyle} />
        <div style={cardStyle}>
          <h2 style={headingStyle}>About Us</h2>
          <p style={paragraphStyle}>
            Welcome to our website. Learn more about our story and values.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
