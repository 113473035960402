import wwt from "../../assets/wastewater.jpg";

const LandingPage = () => {
  return (
    <div className="relative min-h-screen flex items-center justify-center bg-white overflow-hidden">
      <div className="absolute inset-0 z-0">
        <img src={wwt} alt="Wastewater" className="w-full h-full object-cover blur-sm " />
      </div>
      <div className="absolute inset-y-0 left-0 w-1/6 bg-gradient-to-r from-black opacity-50 z-10"></div>
      <div className="absolute inset-y-0 right-0 w-1/6 bg-gradient-to-l from-black opacity-50 z-10"></div>
      <div className="absolute inset-0 flex items-center justify-start z-20">
        <div className="p-8 md:p-16 text-left z-30 w-full">
          <h5 className="heading text-3xl md:text-5xl font-bold mb-4 text-white pt-4 md:pt-20 max-w-3xl">Solusi Terpadu untuk Pengelolaan Limbah Cair</h5>
          <p className="base md:leading-relaxed text-lg md:text-xl mb-6 text-white md:text-white shadow md:shadow-none text-justify text-white max-w-lg shadow-lg">
            Bersama Bioaqualestari, kami menyediakan solusi pengelolaan air limbah yang inovatif dan ramah lingkungan. Bergabunglah dengan kami untuk menjaga kelestarian air dan lingkungan.
          </p>
          <button className="px-6 py-3 bg-blue-600 text-white font-semibold rounded hover:bg-blue-700 transition shadow-lg">Learn More</button>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
