import React from 'react';

const Contact = () => {
  return (
    <div className="flex justify-center items-center min-h-screen relative p-4" style={{backgroundColor: "#3FA2F6"}}>
      <div className="flex flex-wrap items-start w-full lg:flex-nowrap">
        <div className=" text-white rounded-lg shadow-lg py-12 px-6 mt-8 w-full lg:py-24 lg:px-10 lg:mt-20 lg:max-w-xl lg:w-1/2" style={{backgroundColor: "#96C9F4"}}>
          <h1 className="text-3xl lg:text-4xl font-bold mb-4">It's Time To Get In Touch</h1>
          <p className="mb-8 text-sm lg:text-base">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis.</p>
          <button className="bg-white text-pink-500 font-bold py-2 px-4 rounded-full flex items-center">
            <img src="https://img.icons8.com/color/48/000000/whatsapp--v1.png" alt="WhatsApp Icon" className="w-5 h-5 lg:w-6 lg:h-6 mr-2"/>
            WHATSAPP NOW
          </button>
        </div>
        <div className="w-full lg:w-1/2 ">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1983.7256574094327!2d106.7997026!3d-6.2244774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f16eb49a1e83%3A0x4e51b10c032124b2!2sSenayan%20City!5e0!3m2!1sen!2sid!4v1627059054308!5m2!1sen!2sid"
            width="100%"
            height="400"
            allowFullScreen=""
            loading="lazy"
            className="rounded-lg shadow-lg lg:rounded-none lg:shadow-none lg:w-[671px] lg:h-[500px]"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
