import React, { useEffect, useState } from "react";
import { Table, Pagination, Input, Button } from "rsuite";
import { fetchProjects } from "../../../api/projectAPI";
import ImageModal from "../../imageModal";
import ReadMoreModal from "../../readMoreModal";
import ModalProject from "./addProject";
import ModalUpdateProject from "./updateProject"; // Import komponen update
import { handleDeleteProject } from "./deleteProjectHandler"; // Import fungsi delete
import { placeholderImage, isValidUrl } from "../../invalidImage";

const { Column, HeaderCell, Cell } = Table;

const ProjectTable = () => {
  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const fetchData = async () => {
    const projectsData = await fetchProjects();
    setData(projectsData);
    setDisplayData(projectsData.slice(0, limit));
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const start = (page - 1) * limit;
    const end = start + limit;
    setDisplayData(data.slice(start, end));
  }, [page, limit, data]);

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleChangeLimit = (limit) => {
    setLimit(limit);
    setPage(1);
  };

  const handleSearch = (value) => {
    setSearchKeyword(value);
    if (value) {
      const filteredData = data.filter((project) =>
        project.title.toLowerCase().includes(value.toLowerCase())
      );
      setData(filteredData);
      setPage(1);
    } else {
      fetchData();
    }
  };

  const handleAddProject = () => {
    setIsModalOpen(true);
  };

  const handleUpdateProject = (project) => {
    setSelectedProject(project);
    setIsUpdateModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsUpdateModalOpen(false);
  };

  return (
    <div className="flex-1 h-full overflow-y-auto overflow-x-hidden p-4">
      <div className="flex justify-between items-center mb-4">
        <h2>Project Table</h2>
        <Button appearance="primary" onClick={handleAddProject}>
          Tambah Proyek
        </Button>
      </div>
      <div className="mb-4">
        <Input
          placeholder="Search..."
          value={searchKeyword}
          onChange={handleSearch}
        />
      </div>
      <Table
        autoHeight
        data={displayData}
        onRowClick={(rowData) => {
          console.log(rowData);
        }}
        rowHeight={100}
      >
        <Column width={60} align="center" fixed>
          <HeaderCell>No</HeaderCell>
          <Cell>
            {(rowData, rowIndex) => {
              const serialNumber = (page - 1) * limit + rowIndex + 1;
              return <span>{serialNumber}</span>;
            }}
          </Cell>
        </Column>

        <Column width={100}>
          <HeaderCell>Image</HeaderCell>
          <Cell>
            {(rowData) => (
              <ImageModal
                imageUrl={isValidUrl(rowData.image) ? rowData.image : placeholderImage}
              />
            )}
          </Cell>
        </Column>

        <Column width={300}>
          <HeaderCell>Title</HeaderCell>
          <Cell dataKey="title" className="py-2" />
        </Column>

        <Column width={400}>
          <HeaderCell>Description</HeaderCell>
          <Cell>
            {(rowData) => {
              const text = rowData.description;
              const maxLength = 100;
              const truncatedText =
                text.length > maxLength
                  ? text.substring(0, maxLength) + "..."
                  : text;
              return <ReadMoreModal text={text}>{truncatedText}</ReadMoreModal>;
            }}
          </Cell>
        </Column>

        <Column width={150}>
          <HeaderCell>Action</HeaderCell>
          <Cell>
            {(rowData) => {
              const handleDelete = () => {
                handleDeleteProject(rowData.id, data, setData);
              };

              const handleEdit = () => {
                handleUpdateProject(rowData);
              };

              return (
                <span>
                  <a onClick={handleEdit}> Edit </a> |{" "}
                  <a onClick={handleDelete}> Remove </a>
                </span>
              );
            }}
          </Cell>
        </Column>
      </Table>
      <div className="flex justify-end mt-4">
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["total", "-", "limit", "|", "pager"]}
          total={data.length}
          limitOptions={[5, 10, 20, 50]}
          limit={limit}
          activePage={page}
          onChangePage={handleChangePage}
          onChangeLimit={handleChangeLimit}
        />
      </div>

      {/* Modal untuk tambah proyek */}
      {isModalOpen && <ModalProject closeModal={closeModal} />}

      {/* Modal untuk update proyek */}
      {isUpdateModalOpen && selectedProject && (
        <ModalUpdateProject project={selectedProject} onClose={closeModal} />
      )}
    </div>
  );
};

export default ProjectTable;
