import { updateService } from "../../../api/servicesAPI";
import Swal from 'sweetalert2';

export const handleUpdateService = async (
  e,
  id,
  name,
  imageUrl,
  description,
  toggleModal
) => {
  e.preventDefault();

  const updatedFields = {};

  if (name) {
    updatedFields.name = name;
  }

  if (imageUrl) {
    updatedFields.image = imageUrl;
  }

  if (description) {
    updatedFields.description = description;
  }

  if (Object.keys(updatedFields).length === 0) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'No fields to update',
    });
    return;
  }

  const confirmation = await Swal.fire({
    title: 'Are you sure?',
    text: "You are about to update the service's information.",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, update it!'
  });

  if (!confirmation.isConfirmed) {
    return;
  }

  try {
    Swal.fire({
      title: 'Loading...',
      text: 'Please wait while we update the service.',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    await updateService(id, updatedFields);

    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: 'Service updated successfully',
    }).then(() => {
      window.location.reload();
    });

    toggleModal();
  } catch (error) {
    console.error("Failed to update service:", error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Failed to update service',
    });
  }
};
