const headers = {
    "Content-Type": "application/json",
    "x-hasura-admin-secret": process.env.REACT_APP_HASURA_ADMIN_SECRET,
  };
  
  export async function fetchBlogById(id) {
    const response = await fetch(`${process.env.REACT_APP_HASURA_API_BASE_URL}/blogs/${id}`, { headers });
    if (!response.ok) {
      throw new Error("Failed to fetch blog");
    }
    const data = await response.json();
    console.log("Fetched blog by id data:", data); // Log the data for debugging
    return data.blog_by_pk; // Mengembalikan data blog beserta komentar terkaitnya
  };
  
  export async function fetchBlogs() {
    const response = await fetch(`${process.env.REACT_APP_HASURA_API_BASE_URL}/blogs`, { headers });
  
    if (!response.ok) {
      throw new Error("Failed to fetch blogs");
    }
  
    const data = await response.json();
    console.log("Fetched blogs data:", data); // Log the data for debugging
    return data.blog; // Return the blogs array
  }
  
  export async function fetchBlogsRandom() {
    const response = await fetch(`${process.env.REACT_APP_HASURA_API_BASE_URL}/blogs`, { headers });
  
    if (!response.ok) {
      throw new Error("Failed to fetch blogs");
    }
  
    const data = await response.json();
    console.log("Fetched blogs data:", data); // Log the data for debugging
  
    const blogs = data.blog;
    const shuffled = blogs.sort(() => 0.5 - Math.random());
    const selected = shuffled.slice(0, 3); // Pilih 3 blog secara acak
  
    return selected; // Return the randomly selected blogs
  }
  
  export async function fetchBlogsRandom5() {
    const response = await fetch(`${process.env.REACT_APP_HASURA_API_BASE_URL}/blogs`, { headers });
  
    if (!response.ok) {
      throw new Error("Failed to fetch blogs");
    }
  
    const data = await response.json();
    console.log("Fetched blogs data:", data); // Log the data for debugging
  
    const blogs = data.blog;
    const shuffled = blogs.sort(() => 0.5 - Math.random());
    const selected = shuffled.slice(0, 5); // Pilih 5 blog secara acak
  
    return selected; // Return the randomly selected blogs
  }
  
  export async function insertBlog(newBlog) {
    console.log("Sending data to the server:", newBlog); // Log the data to be sent
    const response = await fetch(`${process.env.REACT_APP_HASURA_API_BASE_URL}/blog/insert`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(newBlog),
    });
  
    if (!response.ok) {
      const errorResponse = await response.text();
      console.error("Failed to insert blog:", errorResponse); // Log the error response for debugging
      throw new Error("Failed to insert blog");
    }
  
    const data = await response.json();
    console.log("Inserted blog data:", data); // Log the data for debugging
    return data;
  }
  
  export async function updateBlog(id, updatedBlog) {
    const response = await fetch(`${process.env.REACT_APP_HASURA_API_BASE_URL}/blog/update/${id}`, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(updatedBlog),
    });
  
    if (!response.ok) {
      throw new Error("Failed to update blog");
    }
  
    const data = await response.json();
    console.log("Updated blog data:", data); // Log the data for debugging
    return data;
  }
  
  export async function deleteBlog(id) {
    const response = await fetch(`${process.env.REACT_APP_HASURA_API_BASE_URL}/blog/delete/${id}`, {
      method: 'DELETE',
      headers,
    });
  
    if (!response.ok) {
      throw new Error("Failed to delete blog");
    }
  
    console.log(`Deleted blog with id: ${id}`); // Log the deleted blog id for debugging
  }
  