import React, { useEffect, useState } from "react";
import { fetchServices } from "../../api/servicesAPI";

export default function ServicesPage() {
  const [services, setServices] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getServices = async () => {
      try {
        const servicesData = await fetchServices();
        if (Array.isArray(servicesData)) {
          console.log(servicesData); // Check image paths
          setServices(servicesData);
        } else {
          throw new Error("Products data is not an array");
        }
      } catch (error) {
        setError(error.message);
      }
    };

    getServices();
  }, []);


  const smokeEffect = {
    backgroundImage:
      "url(https://t3.ftcdn.net/jpg/01/47/08/90/240_F_147089075_zliKdNHaoFzmvz45HxEEQe4smUFEHtX6.jpg)",
    animation: "smoke 4s ease-in-out infinite",
    opacity: 0.5,
  };

  // const keyframes = `
  //   @keyframes smoke {
  //     0% { opacity: 0.1; transform: translateY(0) scale(1); }
  //     50% { opacity: 0.5; transform: translateY(-10px) scale(1.05); }
  //     100% { opacity: 0.1; transform: translateY(0) scale(1); }
  //   }
  // `;

  return (
    <div
      className="relative min-h-screen flex items-center justify-center py-10 bg-cover bg-center"
      style={{
        backgroundImage:
          "url(https://t3.ftcdn.net/jpg/01/47/08/90/240_F_147089075_zliKdNHaoFzmvz45HxEEQe4smUFEHtX6.jpg)",
      }}
    >
      {/* <style>{keyframes}</style> */}
      <div className="absolute inset-0 bg-black bg-opacity-60"></div>
      <div
        className="absolute inset-0 bg-no-repeat bg-center bg-cover"
        style={smokeEffect}
      ></div>
      <div className="relative z-10 p-10 rounded-lg w-full max-w-7xl">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-11/12 mx-auto p-6 rounded-lg shadow-lg">
          {services.map((service) => (
            <div
              key={service.id}
              className="bg-gray-800 bg-opacity-75 text-white rounded-lg shadow-lg p-6 flex flex-col items-center text-center"
            >
              <div className="mb-4">
                <img
                  src={service.image}
                  alt={service.name}
                  className="w-16 h-16"
                />
              </div>
              <h3 className="text-lg font-bold mb-2">{service.name}</h3>
              <p className="text-sm">{service.description}</p>
            </div>
          ))}
        </div>
        {error && <div className="text-red-500 mt-4">{error}</div>}
      </div>
    </div>
  );
}
