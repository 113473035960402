import { useEffect, useState } from "react";
// import clientsData from "../dummy/client_dummy.json";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { placeholderImage, isValidUrl } from "../../components/invalidImage";
import { fetchClients } from "../../api/clientAPI";


export default function ClientsPage() {
  const [clients, setClients] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getClients = async () => {
      try {
        const clientsData = await fetchClients();
        if (Array.isArray(clientsData)) {
          setClients(clientsData);
        } else {
          throw new Error("Clients data is not an array");
        }
      } catch (error) {
        setError(error.message);
      }
    };

    getClients();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container mx-auto px-4">
      <div className="text-center mt-10">
        <h1 className="font-extrabold text-4xl mb-4">Klien</h1>
        <p className="mx-auto max-w-3xl text-lg mb-10">
          Kami bangga menjadi mitra terpercaya bagi berbagai perusahaan dan
          organisasi di berbagai sektor industri. Klien-klien kami berasal dari
          beragam bidang, termasuk konstruksi, manufaktur, energi, dan banyak
          lagi.
        </p>
      </div>
      <div className="w-full">
        {clients.length > 0 ? (
          <Slider {...settings}>
            {clients.map((client) => (
              <div key={client.id} className="px-4">
                <div className="flex justify-center items-center h-48">
                  <img
                    src={
                      isValidUrl(client.image) ? client.image : placeholderImage
                    }
                    alt={client.name}
                    className="h-full w-auto max-h-40 object-contain rounded-full"
                  />
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
}
