import React, { useEffect, useState } from "react";
import { Table, Pagination, Input, Button } from "rsuite";
import { fetchBlogs } from "../../../api/blogsAPI";
import ImageModal from "../../imageModal";
import ReadMoreModal from "../../readMoreModal";
import CommentModal from "./commentModal"; // Import CommentModal
import ModalBlog from "./addBlog"; // Import Modal untuk menambah blog
import ModalUpdateBlog from "./updateBlog"; // Import Modal untuk update blog
import { handleDeleteBlog } from "./deleteBlogHandler"; // Import handler untuk delete blog
import { placeholderImage, isValidUrl } from "../../invalidImage";

const { Column, HeaderCell, Cell } = Table;

const BlogTable = () => {
  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);

  const fetchData = async () => {
    const blogsData = await fetchBlogs();
    setData(blogsData);
    setDisplayData(blogsData.slice(0, limit));
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const start = (page - 1) * limit;
    const end = start + limit;
    setDisplayData(data.slice(start, end));
  }, [page, limit, data]);

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleChangeLimit = (limit) => {
    setLimit(limit);
    setPage(1);
  };

  const handleSearch = (value) => {
    setSearchKeyword(value);
    if (value) {
      const filteredData = data.filter((blog) =>
        blog.title.toLowerCase().includes(value.toLowerCase())
      );
      setData(filteredData);
      setPage(1);
    } else {
      fetchData();
    }
  };

  const handleAddBlog = () => {
    setIsModalOpen(true);
  };

  const handleUpdateBlog = (blog) => {
    setSelectedBlog(blog);
    setIsUpdateModalOpen(true);
  };

  const handleViewComments = (blog) => {
    setSelectedBlog(blog);
    setIsCommentModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsUpdateModalOpen(false);
    setIsCommentModalOpen(false);
  };

  return (
    <div className="flex-1 h-full overflow-y-auto overflow-x-hidden p-4">
      <div className="flex justify-between items-center mb-4">
        <h2>Blog Table</h2>
        <Button appearance="primary" onClick={handleAddBlog}>
          Tambah Blog
        </Button>
      </div>
      <div className="mb-4">
        <Input
          placeholder="Search..."
          value={searchKeyword}
          onChange={handleSearch}
        />
      </div>
      <Table
        autoHeight
        data={displayData}
        onRowClick={(rowData) => {
          console.log(rowData);
        }}
        rowHeight={100}
      >
        <Column width={60} align="center" fixed>
          <HeaderCell>No</HeaderCell>
          <Cell>
            {(rowData, rowIndex) => {
              const serialNumber = (page - 1) * limit + rowIndex + 1;
              return <span>{serialNumber}</span>;
            }}
          </Cell>
        </Column>

        <Column width={100}>
          <HeaderCell>Image</HeaderCell>
          <Cell>
            {(rowData) => (
              <ImageModal
                imageUrl={
                  isValidUrl(rowData.image) ? rowData.image : placeholderImage
                }
              />
            )}
          </Cell>
        </Column>

        <Column width={300}>
          <HeaderCell>Title</HeaderCell>
          <Cell>
            {(rowData) => {
              const title = rowData.title;
              const maxLength = 50; // Batas panjang judul sebelum dipotong
              const truncatedTitle =
                title.length > maxLength ? title.substring(0, maxLength) + "..." : title;
              return <ReadMoreModal text={title}>{truncatedTitle}</ReadMoreModal>;
            }}
          </Cell>
        </Column>

        <Column width={400}>
          <HeaderCell>Description</HeaderCell>
          <Cell>
            {(rowData) => {
              const text = rowData.description;
              const maxLength = 100;
              const truncatedText =
                text.length > maxLength
                  ? text.substring(0, maxLength) + "..."
                  : text;
              return (
                <ReadMoreModal text={text}>
                  <div dangerouslySetInnerHTML={{ __html: truncatedText }} />
                </ReadMoreModal>
              );
            }}
          </Cell>
        </Column>

        <Column width={150}>
          <HeaderCell>Author</HeaderCell>
          <Cell dataKey="author" className="py-2" />
        </Column>

        <Column width={200}>
          <HeaderCell>Action</HeaderCell>
          <Cell>
            {(rowData) => {
              const handleDelete = () => {
                handleDeleteBlog(rowData.id, data, setData);
              };

              const handleEdit = () => {
                handleUpdateBlog(rowData);
              };

              const handleComment = () => {
                handleViewComments(rowData);
              };

              return (
                <span>
                  <a onClick={handleEdit}> Edit </a> |{" "}
                  <a onClick={handleDelete}> Remove </a> |{" "}
                  <a onClick={handleComment}> Comment </a>
                </span>
              );
            }}
          </Cell>
        </Column>
      </Table>
      <div className="flex justify-end mt-4">
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["total", "-", "limit", "|", "pager"]}
          total={data.length}
          limitOptions={[5, 10, 20, 50]}
          limit={limit}
          activePage={page}
          onChangePage={handleChangePage}
          onChangeLimit={handleChangeLimit}
        />
      </div>

      {/* Modal untuk tambah blog */}
      {isModalOpen && <ModalBlog closeModal={closeModal} />}

      {/* Modal untuk update blog */}
      {isUpdateModalOpen && selectedBlog && (
        <ModalUpdateBlog blog={selectedBlog} onClose={closeModal} />
      )}

      {/* Modal untuk melihat dan menghapus komentar */}
      {isCommentModalOpen && selectedBlog && (
        <CommentModal
          comments={selectedBlog.comments}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default BlogTable;
