
import { useState, useEffect } from "react";

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleaClick = () => {
    setMobileMenuOpen(false);
  };

  return (
    <header className={`fixed w-full z-50 transition-all duration-300 ${scrollPosition > 0 ? "bg-white shadow-md" : "bg-transparent"}`}>
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8">
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img width={scrollPosition > 0 ? 120 : 200} height={scrollPosition > 0 ? 120 : 200} className={`transition-all duration-300 ${scrollPosition > 0 ? "h-8" : "h-10"} w-auto`} src="https://i.imgur.com/5bqo1uC.png" alt="" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" onClick={() => setMobileMenuOpen(true)}>
            <span className="sr-only">Open main menu</span>
            {/* <Bars3Icon className="h-6 w-6" aria-hidden="true" /> */}
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          <a href="/#AboutUs" className="text-base font-semibold leading-6 text-gray-900">
            Tentang
          </a>
          <a href="/#OurServices" className="text-base font-semibold leading-6 text-gray-900">
            Layanan
          </a>
          <a href="/#OurAdvantages" className="text-base font-semibold leading-6 text-gray-900">
            Kelebihan
          </a>
          <a href="/#ProjectsPage" className="text-base font-semibold leading-6 text-gray-900">
            Project
          </a>
          <a href="/#ClientsPage" className="text-base font-semibold leading-6 text-gray-900">
            Klien
          </a>
          <a href="/blog" className="text-base font-semibold leading-6 text-gray-900">
            Blog
          </a>
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <a href="/login" className="text-base font-semibold leading-6 text-gray-900">
            Log in <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </nav>
      <div className="lg:hidden">
        {mobileMenuOpen && (
          <div className="fixed inset-0 z-50 bg-white p-6">
            <div className="flex items-center justify-between">
              <a href="/#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img width={120} height={120} className="h-8 w-auto" src="https://i.imgur.com/5bqo1uC.png" alt="" />
              </a>
              <button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700" onClick={() => setMobileMenuOpen(false)}>
                <span className="sr-only">Close menu</span>
                {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
              </button>
            </div>
            <div className="mt-6 heading">
              <a href="/#AboutUs" className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" onClick={handleaClick}>
                Tentang
              </a>
              <a href="/#OurServices" className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" onClick={handleaClick}>
                Layanan
              </a>
              <a href="/#OurAdvantages" className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" onClick={handleaClick}>
                Kelebihan
              </a>
              <a href="/#ProjectsPage" className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" onClick={handleaClick}>
                Project
              </a>
              <a href="/#ClientsPage" className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" onClick={handleaClick}>
                Klien
              </a>
              <a href="/#BlogsPage" className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" onClick={handleaClick}>
                Blog
              </a>
              <a href="/api/auth/signin" className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" onClick={handleaClick}>
                Log in
              </a>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}
