const headers = {
  "Content-Type": "application/json",
  "x-hasura-admin-secret": process.env.REACT_APP_HASURA_ADMIN_SECRET,
};

export async function fetchComments() {
  const response = await fetch(`${process.env.REACT_APP_HASURA_API_BASE_URL}/comment`, { headers });

  if (!response.ok) {
    throw new Error("Failed to fetch comment");
  }

  const data = await response.json();
  console.log("Fetched comment data:", data); // Log the data for debugging
  return data.comment;
}

export async function insertComment(newComment) {
  console.log("Sending data to the server:", newComment); // Log the data to be sent
  const response = await fetch(`${process.env.REACT_APP_HASURA_API_BASE_URL}/comment/insert`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(newComment),
  });

  if (!response.ok) {
    const errorResponse = await response.text();
    console.error("Failed to insert comment:", errorResponse); // Log the error response for debugging
    throw new Error("Failed to insert comment");
  }

  const data = await response.json();
  console.log("Inserted comment data:", data); // Log the data for debugging
  return data;
}

export async function deleteComment(id) {
  const response = await fetch(`${process.env.REACT_APP_HASURA_API_BASE_URL}/comment/delete/${id}`, {
    method: 'DELETE',
    headers,
  });

  if (!response.ok) {
    throw new Error("Failed to delete comment");
  }

  console.log(`Deleted comment with id: ${id}`); // Log the deleted comment id for debugging
}
