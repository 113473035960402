import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchBlogsRandom5 } from "../../../api/blogsAPI";

const RecentBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [otherBlogs, setOtherBlogs] = useState([]);
  const [error, setError] = useState(null);
  const { blogId } = useParams();

  useEffect(() => {
    const getBlogs = async () => {
      try {
        const blogData = await fetchBlogsRandom5();
        if (Array.isArray(blogData)) {
          setBlogs(blogData);

          const selectedBlog = blogData.find(
            (blog) => blog.id === parseInt(blogId)
          );
          setBlogs(selectedBlog);

          const filteredBlogs = blogData.filter(
            (blog) => blog.id !== parseInt(blogId)
          );

          // Sort blogs by id in descending order (most recent first)
          const sortedBlogs = filteredBlogs.sort((a, b) => b.id - a.id);

          // Get the first six blogs from the sorted array
          const recentBlogs = sortedBlogs.slice(0, 6);

          setOtherBlogs(recentBlogs);
        } else {
          throw new Error("Blogs data is not an array");
        }
      } catch (error) {
        setError(error.message);
      }
    };

    getBlogs();
  }, [blogId]);

  return (
    <div className="w-full md:px-0">
      <p className="font-semibold text-2xl mx-0 md:mx-5 me-20 mb-4 pt-10">Recent Blogs</p>
      <div className="space-y-4">
        {otherBlogs.map((blog) => (
          <a
            href={`/blog/${blog.id}`}
            key={blog.id}
            className="block font-normal text-xl mx-0 md:mx-5 hover:text-blue-600 text-justify-responsive"
          >
            {blog.title}
          </a>
        ))}
      </div>
    </div>
  );
};

export default RecentBlogs;
