const headers = {
  "Content-Type": "application/json",
  "x-hasura-admin-secret": process.env.REACT_APP_HASURA_ADMIN_SECRET,
};

export async function fetchServices() {
  const response = await fetch(`${process.env.REACT_APP_HASURA_API_BASE_URL}/services`, { headers });

  if (!response.ok) {
    throw new Error("Failed to fetch services");
  }

  const data = await response.json();
  console.log("Fetched services data:", data); // Log the data for debugging
  return data.services; // Return the services array
}

export async function insertService(newService) {
  const response = await fetch(`${process.env.REACT_APP_HASURA_API_BASE_URL}/service/insert`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(newService),
  });

  if (!response.ok) {
    throw new Error("Failed to insert service");
  }

  const data = await response.json();
  console.log("Inserted service data:", data); // Log the data for debugging
  return data;
}

export async function updateService(id, updatedService) {
  const response = await fetch(`${process.env.REACT_APP_HASURA_API_BASE_URL}/service/update/${id}`, {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(updatedService),
  });

  if (!response.ok) {
    throw new Error("Failed to update service");
  }

  const data = await response.json();
  console.log("Updated service data:", data); // Log the data for debugging
  return data;
}

export async function deleteService(id) {
  const response = await fetch(`${process.env.REACT_APP_HASURA_API_BASE_URL}/service/delete/${id}`, {
    method: 'DELETE',
    headers,
  });

  if (!response.ok) {
    throw new Error("Failed to delete service");
  }

  console.log(`Deleted service with id: ${id}`); // Log the deleted service id for debugging
}
