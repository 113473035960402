import React, { useEffect, useState } from "react";
import { fetchProjects } from "../../api/projectAPI";
import DialogXXL from "./ourProjectModal";

export default function Project() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const getProjects = async () => {
      try {
        const projectsData = await fetchProjects();
        if (Array.isArray(projectsData)) {
          setProjects(projectsData);
        } else {
          throw new Error("Projects data is not an array");
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    getProjects();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const smokeEffect = {
    backgroundImage:
      "url(https://t3.ftcdn.net/jpg/02/20/19/54/240_F_220195487_CkKkWzxY06bWIOj8VUPzHbypP8Iom6ho.jpg)",
    animation: "smoke 4s ease-in-out infinite",
    opacity: 0.5,
  };

  return (
    <>
      <div
        className="relative min-h-screen flex flex-col justify-between py-10 bg-cover bg-center"
        style={{
          backgroundImage:
            "url(https://t3.ftcdn.net/jpg/02/20/19/54/240_F_220195487_CkKkWzxY06bWIOj8VUPzHbypP8Iom6ho.jpg)",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-60"></div>
        <div className="absolute inset-0 bg-no-repeat bg-center bg-cover" style={smokeEffect}></div>
        <div className="relative container mx-auto px-4 sm:px-6 lg:px-8 py-10 text-white">
          <div className="text-center" id="ProjectsPage">
            <h1 className="font-extrabold text-4xl mb-3 heading">Project Kami</h1>
            <p className="mx-auto max-w-5xl base">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi tempore illum atque hic rerum, necessitatibus asperiores quaerat nam mollitia itaque culpa similique error dolor! Incidunt consectetur deleniti recusandae ut et?
            </p>
          </div>

          {projects.slice(0, 3).map((project, index) => (
            <div key={project.id} className={`mx-auto flex flex-col md:flex-row gap-8 py-10 max-w-7xl mt-5 ${index % 2 !== 0 ? "md:flex-row-reverse" : ""}`}>
              <img src={project.image} alt={project.title} className="rounded-lg w-full md:w-1/2 object-cover mx-auto md:mx-0" style={{ height: "300px" }} />
              <div className="w-full md:w-1/2 px-4 md:px-10 self-center">
                <p className="text-sm font-semibold">OUR PORTFOLIO</p>
                <p className="text-3xl mt-3 font-bold">{project.title}</p>
                <p className="text-lg mt-5">{project.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center mb-8 relative z-10">
          <button
            onClick={openDialog}
            className="select-none rounded-full bg-sky-500 from-gray-900 to-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md transition-all hover:bg-sky-700 active:opacity-[0.85]"
          >
            View More
          </button>
        </div>
        <DialogXXL isOpen={isDialogOpen} onClose={closeDialog} />
      </div>
    </>
  );
}
