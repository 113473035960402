import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchBlogsRandom5 } from "../../../api/blogsAPI";
import { Panel } from "rsuite";

const RelatedBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState(null);
  const { blogId } = useParams();

  useEffect(() => {
    const getBlogs = async () => {
      try {
        const blogData = await fetchBlogsRandom5();
        if (Array.isArray(blogData)) {
          setBlogs(blogData);
        } else {
          throw new Error("Blogs data is not an array");
        }
      } catch (error) {
        setError(error.message);
      }
    };

    getBlogs();
  }, [blogId]);

  return (
    <>
      <div className="text-center pt-4 pb-6">
        <p className="text-xl text-gray-500">YOU MAY ALSO LIKE</p>
        <p className="text-4xl font-medium">Related Blogs</p>
      </div>
      <div className="p-6 pe-16">
        <div className="flex flex-wrap gap-12 justify-center mx-auto">
          {blogs
            .filter((blog) => blog.id !== parseInt(blogId))
            .map((blog, index) => (
              <a href={`/blog/${blog.id}`} key={blog.id} className="w-80">
                <Panel
                  shaded
                  bordered
                  bodyFill
                  className="mx-auto"
                  style={{
                    display: "inline-block",
                    width: "360px",
                    height: "100%",
                  }}
                >
                  <img src={blog.image} className="h-60 w-full object-cover" alt={blog.title} />
                  <div className="p-4 flex flex-col justify-between h-32 text-center">
                    <p className="text-gray-500 text-sm">{new Date(blog.created_at).toLocaleDateString()}</p>
                    <Panel header={blog.title} />
                  </div>
                </Panel>
              </a>
            ))}
        </div>
      </div>
    </>
  );
};

export default RelatedBlogs;