import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/commons/Sidebar";
import DashboardC from "../../components/admin/dashboard";

const Dashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      // Jika tidak ada token, arahkan pengguna ke halaman login
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-gray-900">
      <Sidebar />
      <DashboardC />
    </div>
  );
};

export default Dashboard;
