import React from "react";

const WhyChooseUs = () => {
    return (
        <div className="flex flex-col lg:flex-row items-center justify-center my-20 space-y-10 lg:space-y-0 lg:space-x-10">
            {/* Left Image Section */}
            <div className="w-full lg:w-6/12">
                <img 
                    src="https://www.tanindo.net/wp-content/uploads/2018/06/Kontraktor-Instalasi-Pengolahan-Air-Limbah-Terbaik.jpg" 
                    alt="Farm" 
                    className="w-full h-auto"
                />
            </div>
            
            {/* Right Text Section */}
            <div className="w-full lg:w-6/12 px-6 lg:px-10">
                <h2 className="text-orange-500 uppercase text-sm font-semibold mb-2">Why Choose Us</h2>
                <h1 className="text-3xl font-bold mb-6">We Providing More Fresh Products</h1>
                <p className="text-gray-700 mb-4">
                    Conducting surveillance in these areas in response to avian influenza being detected in local poultry and emu farms to stop its spread. We host a series of webinars to assist landholders with animal nutrition, farm water and soil.
                </p>
                
                <div className="flex flex-col sm:flex-row space-y-6 sm:space-y-0 sm:space-x-8 mt-6">
                    {/* Extended Warranty */}
                    <div className="flex items-center">
                        <i className="fas fa-shield-alt text-orange-500 text-2xl"></i>
                        <div className="ml-4">
                            <h4 className="text-xl font-semibold">Extended Warranty</h4>
                            <p className="text-gray-600 text-sm">We put the extra in your ordinary for the balance life.</p>
                        </div>
                    </div>
                    
                    {/* Friendly Support */}
                    <div className="flex items-center">
                        <i className="fas fa-headset text-orange-500 text-2xl"></i>
                        <div className="ml-4">
                            <h4 className="text-xl font-semibold">Friendly Support</h4>
                            <p className="text-gray-600 text-sm">The Industrial is responsible for minor and the codes.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyChooseUs;
