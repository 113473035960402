import React, { useState, useEffect } from "react";
import { Table, Pagination, Input, Button } from "rsuite";
import { fetchClients } from "../../../api/clientAPI";
import { placeholderImage, isValidUrl } from "../../invalidImage";
import ImageModal from "../../imageModal";
import ModalClient from "./addClient";
import ModalUpdateClient from "./updateClient"; // Import komponen update
import { handleDeleteClient } from "./deleteClientHandler"; // Import fungsi delete

const { Column, HeaderCell, Cell } = Table;

const ClientTable = () => {
  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null); // State untuk menyimpan klien yang akan diupdate

  const fetchData = async () => {
    const clientsData = await fetchClients();
    setData(clientsData);
    setDisplayData(clientsData.slice(0, limit));
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const start = (page - 1) * limit;
    const end = start + limit;
    setDisplayData(data.slice(start, end));
  }, [page, limit, data]);

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleChangeLimit = (limit) => {
    setLimit(limit);
    setPage(1);
  };

  const handleSearch = (value) => {
    setSearchKeyword(value);
    if (value) {
      const filteredData = data.filter((client) =>
        client.name.toLowerCase().includes(value.toLowerCase())
      );
      setData(filteredData);
      setPage(1);
    } else {
      fetchData();
    }
  };

  const handleAddClient = () => {
    setIsModalOpen(true);
  };

  const handleUpdateClient = (client) => {
    setSelectedClient(client); // Simpan klien yang dipilih
    setIsUpdateModalOpen(true); // Buka modal update
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsUpdateModalOpen(false);
  };

  return (
    <div className="flex-1 h-full overflow-y-auto overflow-x-hidden p-4">
      <div className="flex justify-between items-center mb-4">
        <h2>Client Table</h2>
        <Button appearance="primary" onClick={handleAddClient}>
          Tambah Klien
        </Button>
      </div>
      <div className="mb-4">
        <Input
          placeholder="Search..."
          value={searchKeyword}
          onChange={handleSearch}
        />
      </div>
      <Table
        autoHeight
        data={displayData}
        onRowClick={(rowData) => {
          console.log(rowData);
        }}
        rowHeight={100}
      >
        <Column width={60} align="center" fixed>
          <HeaderCell>No</HeaderCell>
          <Cell>
            {(rowData, rowIndex) => {
              const serialNumber = (page - 1) * limit + rowIndex + 1;
              return <span>{serialNumber}</span>;
            }}
          </Cell>
        </Column>

        <Column width={100}>
          <HeaderCell>Image</HeaderCell>
          <Cell>
            {(rowData) => (
              <ImageModal
                imageUrl={
                  isValidUrl(rowData.image) ? rowData.image : placeholderImage
                }
              />
            )}
          </Cell>
        </Column>

        <Column width={300}>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="name" className="py-2" />
        </Column>

        <Column width={150}>
          <HeaderCell>Action</HeaderCell>
          <Cell>
            {(rowData) => {
              const handleDelete = () => {
                handleDeleteClient(rowData.id, data, setData);
              };

              const handleEdit = () => {
                handleUpdateClient(rowData);
              };

              return (
                <span>
                  <a onClick={handleEdit}> Edit </a> |{" "}
                  <a onClick={handleDelete}> Remove </a>
                </span>
              );
            }}
          </Cell>
        </Column>
      </Table>
      <div className="flex justify-end mt-4">
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["total", "-", "limit", "|", "pager"]}
          total={data.length}
          limitOptions={[5, 10, 20, 50]}
          limit={limit}
          activePage={page}
          onChangePage={handleChangePage}
          onChangeLimit={handleChangeLimit}
        />
      </div>

      {/* Modal untuk tambah klien */}
      {isModalOpen && <ModalClient closeModal={closeModal} />}

      {/* Modal untuk update klien */}
      {isUpdateModalOpen && selectedClient && (
        <ModalUpdateClient client={selectedClient} onClose={closeModal} />
      )}
    </div>
  );
};

export default ClientTable;
