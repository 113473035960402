import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="bg-sky-600 shadow dark:bg-sky-600 mt-0">
      <div className="w-full max-w-7xl mx-auto p-4 md:py-8">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <div className="flex flex-col sm:flex-row items-center mb-4 sm:mb-0">
            <img width={200} height={200} src="https://i.imgur.com/5bqo1uC.png" className="h-16 w-16 sm:h-20 sm:w-20 px-2" alt="Flowbite Logo" />
            <span className="text-center sm:text-left text-2xl font-semibold whitespace-nowrap text-black mt-2 sm:mt-0 sm:ml-4">
              Bio Aqua Lestari
              <br />
              <a className="text-sm font-medium text-white dark:text-gray-400">PT. Deha Corp.</a>
            </span>
          </div>
          <ul className="flex flex-col sm:flex-row sm:space-x-4 items-center text-sm font-medium text-white dark:text-gray-400">
            <li>
              <a href="#AboutUs" className="hover:underline mb-2 sm:mb-0">
                About
              </a>
            </li>
            <li>
              <a href="#" className="hover:underline mb-2 sm:mb-0">
                Calling Us
              </a>
            </li>
            <li>
              <a href="#" className="hover:underline">
                Maps
              </a>
            </li>
          </ul>
        </div>
        <div className="flex justify-center space-x-8 mt-4">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-white">
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-white">
            <FontAwesomeIcon icon={faTwitter} size="2x" />
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-white">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-white">
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
          </a>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-6" />
        <center>
          <span className="block text-sm text-white sm:text-center dark:text-white">
            © 2024{" "}
            <a href="" className="hover:underline">
              Deha Web Design™
            </a>
            . All Rights Reserved.
          </span>
        </center>
      </div>
    </footer>
  );
};

export default Footer;
