import React, { useState } from "react";
import { handleUpdateBlog } from "./updateBlogHandler";
import { placeholderImage, isValidUrl } from "../../invalidImage";
import { Modal, Button } from "rsuite";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Styles for the editor

const ModalUpdateBlog = ({ blog, onClose }) => {
  const [title, setTitle] = useState(blog.title);
  const [imageUrl, setImageUrl] = useState(blog.image);
  const [description, setDescription] = useState(blog.description);
  const [author, setAuthor] = useState(blog.author);

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  return (
    <Modal open={true} onClose={onClose} size="md">
      <Modal.Header>
        <Modal.Title>Update Blog</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={(e) =>
            handleUpdateBlog(
              e,
              blog.id,
              title,
              imageUrl,
              description,
              author,
              onClose
            )
          }
        >
          <div className="mb-4">
            <label htmlFor="title" className="block mb-2">
              Title
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-2 border rounded"
              placeholder="Enter blog title"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="imageUrl" className="block mb-2">
              Image URL
            </label>
            <input
              type="text"
              id="imageUrl"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              className="w-full p-2 border rounded"
              placeholder="Enter image URL"
            />
            {imageUrl && (
              <div className="mt-4">
                <img
                  src={isValidUrl(imageUrl) ? imageUrl : placeholderImage}
                  alt="Selected"
                  className="rounded-lg object-contain h-full w-full"
                />
              </div>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="description" className="block mb-2">
              Description
            </label>
            <ReactQuill
              value={description}
              onChange={handleDescriptionChange}
              className="bg-white"
              theme="snow"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="author" className="block mb-2">
              Author
            </label>
            <input
              type="text"
              id="author"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
              className="w-full p-2 border rounded"
              placeholder="Enter author's name"
            />
          </div>
          <div className="flex justify-end">
            <Button type="submit" appearance="primary">
              Update Blog
            </Button>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} appearance="subtle">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalUpdateBlog;
