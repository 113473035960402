import { insertService } from "../../../api/servicesAPI";
import Swal from 'sweetalert2';

export const handleAddService = async (
  e,
  name,
  imageUrl,
  description,
  closeModal
) => {
  e.preventDefault();

  if (!name || !description) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Name and description are required',
    });
    return;
  }

  try {
    Swal.fire({
      title: 'Adding...',
      text: 'Please wait while we add the service.',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    await insertService({ name, image: imageUrl, description });

    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: 'Service added successfully',
    }).then(() => {
      window.location.reload();
    });

    closeModal();
  } catch (error) {
    console.error("Failed to add service:", error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Failed to add service',
    });
  }
};
