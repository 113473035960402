import React, { useState } from "react";
import { Modal, Button } from "rsuite";
import { placeholderImage, isValidUrl } from "./invalidImage";

const ImageModal = ({ imageUrl }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <img
        src={imageUrl}
        alt="Blog Image"
        className="w-16 h-16 object-cover rounded-md cursor-pointer"
        onClick={handleOpen}
        onError={(e) => {
          e.target.src = placeholderImage;
        }}
      />

      <Modal open={open} onClose={handleClose} size="md">
        <Modal.Header>
          <Modal.Title>Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={imageUrl} alt="Blog Image" className="w-full h-auto" />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="primary">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImageModal;
