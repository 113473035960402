import React, { useEffect, useState } from "react";
import { fetchBlogs } from "../../../api/blogsAPI";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div 
      className="absolute top-1/2 right-0 transform -translate-y-1/2 p-2 bg-gray-800 text-white cursor-pointer z-10 rounded-full"
      onClick={onClick}
    >
      <i className="fas fa-chevron-right"></i>
    </div>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div 
      className="absolute top-1/2 left-0 transform -translate-y-1/2 p-2 bg-gray-800 text-white cursor-pointer z-10 rounded-full"
      onClick={onClick}
    >
      <i className="fas fa-chevron-left"></i>
    </div>
  );
}

const BlogsPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getBlogs = async () => {
      try {
        const blogsData = await fetchBlogs();
        setBlogs(blogsData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    getBlogs();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const chunkedBlogs = [];
  for (let i = 0; i < blogs.length; i += 2) {
    chunkedBlogs.push(blogs.slice(i, i + 2));
  }

  return (
    <div className="relative">
      <center>
        <h1 className="font-extrabold">Latest Update</h1>
        <h1 className="text-4xl">Blogs List</h1>
      </center>
      <Slider {...settings} className="mx-10 my-10">
        {chunkedBlogs.map((chunk, index) => (
          <div key={index} className="p-4">
            <div className="flex flex-wrap gap-8 justify-center">
              {chunk.map((blog) => (
                <Link to={`/blog/${blog.id}`} key={blog.id}>
                  <div className="mb-10 flex flex-col md:flex-row gap-4 max-w-xl">
                    <img 
                      src={blog.image} 
                      alt={blog.title} 
                      className="my-4 w-60 h-60 object-cover" 
                    />
                    <div className="flex flex-col justify-center">
                      <h2 className="text-xl font-bold w-64">{blog.title}</h2>
                      <div 
                        className="mb-2 w-56"
                        dangerouslySetInnerHTML={{
                          __html: blog.description.length > 100 
                            ? `${blog.description.substring(0, 100)}...`
                            : blog.description
                        }}
                      />
                      <div className="text-gray-600">Author: {blog.author}</div>
                      <div className="text-gray-600">Created at: {new Date(blog.created_at).toLocaleDateString()}</div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default BlogsPage;
