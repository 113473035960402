import { insertBlog } from "../../../api/blogsAPI";
import Swal from 'sweetalert2';

export const handleAddBlog = async (
  e,
  title,
  imageUrl,
  description,
  author,
  closeModal
) => {
  e.preventDefault();

  if (!title || !description || !author) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Title, description, and author are required',
    });
    return;
  }

  try {
    Swal.fire({
      title: 'Adding...',
      text: 'Please wait while we add the blog.',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    await insertBlog({ title, image: imageUrl, description, author });

    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: 'Blog added successfully',
    }).then(() => {
      window.location.reload();
    });

    closeModal();
  } catch (error) {
    console.error("Failed to add blog:", error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Failed to add blog',
    });
  }
};
