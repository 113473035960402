import { insertProject } from "../../../api/projectAPI";
import Swal from 'sweetalert2';

export const handleAddProject = async (
  e,
  title,
  imageUrl,
  description,
  closeModal
) => {
  e.preventDefault();

  if (!title || !description) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Title and description are required',
    });
    return;
  }

  try {
    Swal.fire({
      title: 'Adding...',
      text: 'Please wait while we add the project.',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    await insertProject({ title, image: imageUrl, description });

    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: 'Project added successfully',
    }).then(() => {
      window.location.reload();
    });

    closeModal();
  } catch (error) {
    console.error("Failed to add project:", error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Failed to add project',
    });
  }
};
