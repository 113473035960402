import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Auth0Lock from "auth0-lock";

const Callbackk = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const lock = new Auth0Lock(
      process.env.REACT_APP_AUTH0_CLIENT_ID,
      process.env.REACT_APP_AUTH0_DOMAIN
    );

    // Listen for the authenticated event
    lock.on("authenticated", (authResult) => {
      console.log("Access Token:", authResult.accessToken);

      // Simpan token JWT ke localStorage
      localStorage.setItem("token", authResult.idToken);

      // Redirect ke halaman dashboard setelah berhasil login
      navigate("/admin/dashboard");
    });

    // Handle authorization errors
    lock.on("authorization_error", (err) => {
      console.error("Authorization Error", err);
      navigate("/login");
    });

    // Resume auth in case the page was reloaded during the authentication process
    lock.resumeAuth(window.location.hash, (error, authResult) => {
      if (error) {
        console.error("Error parsing hash", error);
        return navigate("/login");
      }

      if (authResult && authResult.idToken) {
        console.log("Auth Result:", authResult);
        localStorage.setItem("token", authResult.idToken);
        navigate("/admin/dashboard"); // Redirect ke halaman dashboard setelah berhasil login
      } else {
        console.error("Auth Result is null or missing idToken");
        navigate("/login");
      }
    });
  }, [navigate]);

  return <div>Loading...</div>;
};

export default Callbackk;
