import React, { useState } from "react";
import { insertComment } from "../../../api/commentAPI";

const CommentSection = ({ blog, setBlog }) => {
  const [newComment, setNewComment] = useState("");
  const [newName, setNewName] = useState("");
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    comment: "",
  });

  const handleInsertComment = async () => {
    if (newComment.trim() === "" || newName.trim() === "") {
      alert("Comment and Name cannot be empty");
      return;
    }

    try {
      const commentData = {
        comment: newComment,
        blog_id: blog.id,
        name: newName,
      };
      const insertedComment = await insertComment(commentData);

      if (!insertedComment.created_at) {
        insertedComment.created_at = new Date().toISOString();
      }

      setBlog((prevBlog) => {
        if (prevBlog) {
          return {
            ...prevBlog,
            comments: [...prevBlog.comments, insertedComment],
          };
        }
        return prevBlog;
      });
      setNewComment("");
      setNewName("");

      window.location.reload();
    } catch (error) {
      setError(error.message);
      console.error(error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleInsertComment();
  };

  return (
    <>
      <div className="text-3xl mb-10 font-semibold text-grey text-center">
        <p>Comments</p>
      </div>
      <div>
        {error ? (
          <p>Error: {error}</p>
        ) : (
          <div className="mx-4 md:mx-auto md:w-8/12">
            {blog.comments.map((comment) => (
              <div
                key={comment.id}
                className="block p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 mb-10"
              >
                <p>
                  <strong>{comment.name}</strong>
                </p>
                <p>
                  <em>{new Date(comment.created_at).toLocaleString()}</em>
                </p>
                <p className="text-lg">{comment.comment}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="mx-4 md:mx-auto  md:w-8/12">
        <p className="text-xl md:text-3xl font-semibold text-black mt-10">
          Write a comment
        </p>
        <p className="text-base md:text-lg text-grey mb-4">
          Your email address will not be published.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
            <div className="w-full md:w-1/2">
              <p className="font-semibold pb-2 text-lg">Your name</p>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="border p-2 w-full mb-2 rounded-lg"
                placeholder="Enter your name"
              />
            </div>
            <div className="w-full md:w-1/2">
              <p className="font-semibold pb-2 text-lg">Your email address</p>
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="border p-2 w-full mb-2 rounded-lg"
                placeholder="Enter your email"
              />
            </div>
          </div>
          <p className="font-semibold pb-2 pt-4 text-lg">Your comment</p>
          <textarea
            name="comment"
            value={formData.comment}
            onChange={handleChange}
            className="border p-2 w-full mb-2 rounded-lg  md:w-11/12"
            placeholder="Write your comment here"
            rows="4"
          />
          <button
            type="submit"
            className="bg-black text-white px-4 py-2 rounded mt-4"
          >
            Post Comment
          </button>
        </form>
      </div>
    </>
  );
};

export default CommentSection;
