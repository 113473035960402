import React, { useEffect, useState } from "react";
import { fetchBlogs } from "../../../api/blogsAPI";

const RecentComments = () => {
  const [otherComments, setOtherComments] = useState([]);

  const fetchAllComments = async () => {
    try {
      const blogs = await fetchBlogs(); // Fetch all blogs

      // Extract all comments from all blogs
      const allComments = blogs.flatMap(blog => blog.comments || []);

      // Sort comments by created_at in descending order (most recent first)
      const sortedComments = allComments.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      // Get the first six comments from the sorted array
      const recentComments = sortedComments.slice(0, 6);

      setOtherComments(recentComments);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  useEffect(() => {
    fetchAllComments();
  }, []);

  return (
    <div className="w-full md:px-0">
      <p className="font-semibold text-2xl mb-4 mx-0 md:mx-5 pt-10">Recent Comments</p>
      <div className="space-y-4">
        {otherComments && otherComments.length > 0 &&
          otherComments.map((comment) => (
            <a href={`/blog/${comment.blog_id}`} key={comment.id}>
              <p className="block font-normal text-xl mb-10 mx-0 md:mx-5 hover:text-blue-600 text-justify-responsive">
                <strong>{comment.name}</strong> In {comment.comment}
              </p>
            </a>
          ))
        }
      </div>
    </div>
  );
};

export default RecentComments;
