import React, { useState } from "react";
import { Modal, Button } from "rsuite";

const ReadMoreModal = ({ text, children }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <span onClick={handleOpen} style={{ color: 'blue', cursor: 'pointer' }}>
        {children}
      </span>

      <Modal open={open} onClose={handleClose} size="md">
        <Modal.Header>
          <Modal.Title>Full Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="primary">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReadMoreModal;
