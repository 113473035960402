import React, { useState } from "react";
import { Modal, Button, List, IconButton } from "rsuite";
import { Trash } from '@rsuite/icons';
import { deleteComment } from "../../../api/commentAPI";

const CommentModal = ({ comments, onClose }) => {
  const [commentList, setCommentList] = useState(comments);

  const handleDeleteComment = async (id) => {
    try {
      await deleteComment(id);
      setCommentList(commentList.filter(comment => comment.id !== id));
    } catch (error) {
      console.error("Failed to delete comment:", error);
    }
  };

  return (
    <Modal open={true} onClose={onClose} size="md">
      <Modal.Header>
        <Modal.Title>Comments</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <List>
          {commentList.map((comment) => (
            <List.Item key={comment.id} index={comment.id}>
              <div className="flex justify-between items-center">
                <div>
                  <strong>{comment.name}</strong>
                  <p>{comment.comment}</p>
                </div>
                <IconButton
                  icon={<Trash />}
                  color="red"
                  appearance="subtle"
                  onClick={() => handleDeleteComment(comment.id)}
                />
              </div>
            </List.Item>
          ))}
        </List>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} appearance="primary">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CommentModal;
